import { TobPricingTable, TobCustomerBasicInfo, TobCustomerInfo, TobCredits } from '@/types'
import { rest } from '@/utils'

export const getTobPricingTable = async (): Promise<TobPricingTable> => {
  const res = await rest.get('/v1/tob/get-pricing-table')
  return res.data
}

export const getTobSubscriptionCheckoutSession = async (data: {
  price_lookup_key: string
  success_url: string
  cancel_url: string
}): Promise<string> => {
  const res = await rest.post('/v1/tob/create-subscription-checkout-session', data)
  return res.data
}

export const updateTobCustomerInfo = async (data: TobCustomerBasicInfo) => {
  const res = await rest.put('/v1/tob/customer', data)
  return res.data
}

export const getTobCustomerInfo = async (): Promise<TobCustomerInfo> => {
  const res = await rest.get('/v1/tob/customer')
  return res.data
}

export const getTobCredits = async (): Promise<TobCredits> => {
  const res = await rest.get('/v1/tob/credits')
  return res.data
}

export const getTobTopupCheckoutSession = async (data: {
  price_lookup_key: string
  quantity: number
  success_url: string
  cancel_url: string
}): Promise<string> => {
  const res = await rest.post('/v1/tob/create-top-up-checkout-session', data)
  return res.data
}

export const postApiKeys = async (data: { name: string }): Promise<any> => {
  const res = await rest.post('/v1/tob/api-keys', data, {
    meta: { silent: true },
  } as any)
  return res.data
}

export const getApiKeys = async (): Promise<any> => {
  const res = await rest.get('/v1/tob/api-keys')
  return res.data
}

export const deleteApiKey = async (id: string): Promise<any> => {
  const res = await rest.delete(`/v1/tob/api-keys/${id}`)
  return res.data
}

export const patchApiKey = async (
  id: string,
  data: {
    name: string
  },
): Promise<any> => {
  const res = await rest.patch('/v1/tob/api-keys', {
    ...data,
    key_id: id,
  })
  return res.data
}
