'use client'

import { Broadcast } from '@/types'
import { useCachedConfig } from './useConfig'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getLocalStorage, setLocalStorage } from '@/utils'
import dayjs from 'dayjs'
import useInterval from 'use-interval'
import { atom, useAtom, useSetAtom } from 'jotai'
import { hasBroadcastAtom } from '@/atoms'
import useAccountNotice from './useAccountNotice'
import { uniq } from 'lodash-es'

export interface UseBroadcastsResp {
  broadcasts: Broadcast[]
  markAsRead: (id: string) => void
  loading: boolean
}

const sessionReadedBroadcastIdsAtom = atom<string[]>([])

const useBroadcasts = (): UseBroadcastsResp => {
  const { data, isValidating: loading } =
    useCachedConfig<Broadcast[]>('broadcasts')
  const [sessionReadedBroadcastIds, setSessionReadedBroadcastIds] = useAtom(
    sessionReadedBroadcastIdsAtom,
  )

  const setHasBroadcast = useSetAtom(hasBroadcastAtom)
  const accountNotice = useAccountNotice()

  const allBroadcasts: Broadcast[] = useMemo(() => {
    return [accountNotice, ...(data ?? [])].filter(Boolean) as Broadcast[]
  }, [data, accountNotice])

  const getValidIds = useCallback(() => {
    const newValidIds = (allBroadcasts ?? [])
      .filter((b: Broadcast) => {
        // disabled check
        if (b.disabled) {
          return false
        }

        // start_time and end_time check
        const now = dayjs()
        if (b.start_time) {
          const start = dayjs(b.start_time)
          if (!start.isValid() || start.isAfter(now)) {
            return false
          }
        }

        if (b.end_time) {
          const end = dayjs(b.end_time)
          if (!end.isValid() || end.isBefore(now)) {
            return false
          }
        }

        return true
      })
      .map((b: Broadcast) => b.id)
      .sort()
    return newValidIds
  }, [allBroadcasts])

  const [validIds, setValidIds] = useState<string[]>(getValidIds)

  const updateValidIds = useCallback(() => {
    const newValidIds = getValidIds()
    if (newValidIds.join() !== validIds.join()) {
      setValidIds(newValidIds)
    }
  }, [getValidIds, validIds])

  useEffect(() => {
    updateValidIds()
  }, [updateValidIds])

  useInterval(updateValidIds, 1000 * 60) // 1 minute

  const [broadcastId2ReadTime, setBroadcastId2ReadTime] = useState<
    Record<string, number>
  >(() => {
    return getLocalStorage('broadcasts-read-time') ?? {}
  })

  const markAsRead = useCallback(
    (id: string) => {
      setBroadcastId2ReadTime((prev: Record<string, number>) => {
        const now = Date.now()
        const result = { ...prev, [id]: now }
        setSessionReadedBroadcastIds((old) => uniq([...old, id]))
        setLocalStorage('broadcasts-read-time', result)
        return result
      })
    },
    [setSessionReadedBroadcastIds],
  )

  const broadcasts = useMemo(() => {
    const unreadBroadcasts =
      allBroadcasts?.filter((b: Broadcast | null) => {
        if (!b) {
          return false
        }

        if (!validIds.includes(b.id)) {
          return false
        }

        // read check
        if (b.frequency !== 'always') {
          if (sessionReadedBroadcastIds.includes(b.id)) {
            return false
          }

          if (broadcastId2ReadTime[b.id] && b.frequency === 'once') {
            return false
          }
        }
        return true
      }) ?? []

    const result = unreadBroadcasts.filter(Boolean) as Broadcast[]

    return result
  }, [allBroadcasts, broadcastId2ReadTime, validIds, sessionReadedBroadcastIds])

  useEffect(() => {
    setHasBroadcast(broadcasts?.length > 0)
  }, [broadcasts, setHasBroadcast])

  return {
    broadcasts,
    markAsRead,
    loading,
  }
}

export default useBroadcasts
