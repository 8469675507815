import { Broadcast, TobCustomerInfo } from '@/types'
import { usePathname } from 'next/navigation'
import { useCachedTobCustomerInfo } from './useTobCustomerInfo'

const useTobAccountNotice = (): Broadcast | null => {
  const { data: customerInfo } = useCachedTobCustomerInfo()
  const pathname = usePathname()

  // show account notice if user has active tob plan
  if (
    !(customerInfo as TobCustomerInfo)?.stripe_connect?.subscription ||
    pathname?.startsWith('/haiper-api')
  )
    return null

  return {
    id: 'tob-account-notice',
    title: 'This Account Is for API Services Only',
    content: '',
    variant: 'info',
    frequency: 'session',
    // frequency: 'always',
  }
}

export default useTobAccountNotice
