// global padding, used by broadcasts

import { hasBroadcastAtom } from '@/atoms'
import { useAtomValue } from 'jotai'

const globalPaddingMap = {
  broadcast: {
    className: 'md:pt-12',
    absoluteClassName: 'md:top-12',
  },
}

export interface GlobalPadding {
  height?: number
  className?: string
  absoluteClassName?: string
}

export default function useGlobalPadding(): GlobalPadding {
  const hasBroadcast = useAtomValue(hasBroadcastAtom)
  const padding = hasBroadcast ? globalPaddingMap.broadcast : {}
  return padding
}
