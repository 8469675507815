import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cls } from '@/utils'
import { DEFAULT_TOOLTIP_DELAY_DURATION } from '@/constants'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

Tooltip.defaultProps = {
  ...Tooltip.defaultProps,
  delayDuration: DEFAULT_TOOLTIP_DELAY_DURATION,
}

const TooltipPortal = TooltipPrimitive.Portal

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipArrow = TooltipPrimitive.Arrow

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cls(
      'z-60 overflow-hidden rounded-md border border-[#27272A] bg-[#09090B] px-3 py-1.5 text-body-md text-text shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipPortal, TooltipArrow }
