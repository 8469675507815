import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export default function useWaitTime(coolDown: Date | Dayjs | string | null | undefined) {
  const endTime = useMemo(() => (coolDown ? dayjs(coolDown) : null), [coolDown])
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const update = useCallback(() => {
    const currentTime = dayjs()
    if (!endTime || !endTime.isValid() || endTime.isBefore(currentTime)) {
      setWaitTime(0)
    } else {
      setWaitTime(Math.max(0, endTime.diff(currentTime, 'second')))
    }
  }, [endTime])

  const [waitTime, setWaitTime] = useState(0)

  useEffect(() => {
    update()
    timerRef.current = setInterval(update, 1000)

    return () => {
      const timer = timerRef.current
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [update])

  return waitTime
}
