import { isProduction, safeJsonParse } from '@/utils'
import axios from 'axios'
// import sleep from 'sleep-promise'

// const configHost = '/config'
const configHost = 'https://config.haiper.ai'

export const getConfig = async <T = Record<string, any>>(
  key: string,
): Promise<T | null> => {
  // await sleep(3 * 1000)
  const env = isProduction ? 'pro' : 'dev'
  const appCode = 'webapp'
  const res = await axios.get(`${configHost}/${appCode}-${env}-${key}`)
  const obj = typeof res.data === 'string' ? safeJsonParse(res.data) : res.data
  return obj?.value ?? null
}
