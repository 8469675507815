import { PublicProfile } from '@/types'
import { rest } from '@/utils'

export const getPublicProfile = async (id: string): Promise<PublicProfile> => {
  const res = await rest.get(`/v1/user/${id}/basic`)
  return res.data
}

export const deleteAccountApi = async (): Promise<any> => {
  const res = await rest.put('/v1/user/delete')
  return res.data
}

export const restoreAccountApi = async (): Promise<any> => {
  const res = await rest.put('/v1/user/restore')
  return res
}
