import { getConfig } from '@/service/config.service'
import useService, { useCachedService } from '@banyudu/use-service'

const useConfig = useService(getConfig, (key) => Boolean(key), {
  refreshInterval: 1000 * 60 * 10, // 10 minutes
  keepPreviousData: true,
})

export const useCachedConfig = useCachedService(
  getConfig,
  (key) => Boolean(key),
  {
    refreshInterval: 1000 * 60 * 10, // 10 minutes
    keepPreviousData: true,
  },
)

export default useConfig
