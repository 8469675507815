import { forwardRef, useState } from 'react'

import Button, { ButtonProps } from '../button'

const LoadingButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick, ...props }, ref) => {
    const [loading, setLoading] = useState(false)

    const handleClick = async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      setLoading(true)
      try {
        await onClick?.(e)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        throw error
      }
    }
    return (
      <Button
        loading={loading}
        onClick={handleClick}
        {...props}
        ref={ref}
        disabled={props.disabled || loading}
      />
    )
  },
)

LoadingButton.displayName = 'LoadingButton'

export default LoadingButton
